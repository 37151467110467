var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.privateBookInfo
    ? _c(
        "b-modal",
        {
          attrs: {
            title: "您已經完成客製化課程需求的設定",
            "ok-only": "",
            centered: "",
            "hide-footer": "",
            "header-bg-variant": "primary",
          },
          on: {
            change: _vm.close,
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-center",
            },
            [
              _c(
                "div",
                {
                  staticClass: "px-2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "my-1",
                    },
                    [
                      _vm._v("課程時間："),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.privateBookInfo.classApplication.classTime)
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "my-1",
                    },
                    [
                      _vm._v("顧問："),
                      _vm.privateBookInfo.teacher.chineseName === "" &&
                      _vm.privateBookInfo.teacher.englishName === ""
                        ? _c(
                            "span",
                            {
                              staticClass: "badge badge-secondary",
                            },
                            [_vm._v("未指定")]
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                `${
                                  _vm.privateBookInfo.teacher.englishName !== ""
                                    ? _vm.privateBookInfo.teacher.englishName
                                    : _vm.privateBookInfo.teacher.chineseName
                                }`
                              )
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "my-1",
                    },
                    [
                      _vm._v("教材："),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.privateBookInfo.material.file,
                          },
                        },
                        [_vm._v("點我下載")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "my-1",
                    },
                    [
                      _vm._v(
                        "備註： " +
                          _vm._s(_vm.privateBookInfo.classApplication.note)
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }